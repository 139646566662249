<script>
import { VueTyper } from "vue-typer";
import {
  ArrowRightCircleIcon,
  ArrowUpIcon,
  EyeIcon,
  ArrowRightIcon,
  HeartIcon,
  BoldIcon,
  FeatherIcon,
  CodeIcon,
  MonitorIcon,
  UserCheckIcon,
  GitMergeIcon,
  SettingsIcon,
  VideoIcon,
} from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";
import countTo from "vue-count-to";
import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";

/**
 * Index-landing-one component
 */
export default {
  data() {
    return {     featuresData: [
        {
          icon: "uil uil-file-search-alt",
          title: "Stratagy & Research",
          description:
            " To ensure our digital marketing efforts are efficient and effective, and in-line with your long-term goals.",
        },
        {
          icon: "uil uil-edit-alt",
          title: "Increase Visibility",
          description:
            "Analyze your business to design and develop any type of stunning and creative asstes, that drive results for your business.",
        },
                {
          icon: "uil uil-airplay",
          title: "Grow Your Customer Base",
          description:
            "High search results for relevant keywords is one key way to ensure that your website remains top-of-mind for potential customers. ",
        },
        {
          icon: "uil uil-vector-square",
          title: "Increase Sales",
          description:
            "Grow and engage your audience with an effective social media strategy. We help to establish and maintain social presence. ",
        },
        {
          icon: "uil uil-calendar-alt",
          title: "Performance Media",
          description:
            "Stand out from the crowd with a targeted social media advertising, Google Ads campaign that propels your message through the online noise.",
        },
        {
          icon: "uil uil-clock",
          title: "Proprietary Processes",
          description:
            "we surround ourselves with other leaders, and our education never stops. Our Proprietary Processes are time-tested and field proven.",
        },
      ],
      
      };
  },
  components: {
    Navbar,
    "vue-typer": VueTyper,
    Switcher,
    Footer,
    Carousel,
    Slide,
    ArrowUpIcon,
    EyeIcon,
    VideoIcon,
    ArrowRightIcon,
    ArrowRightCircleIcon,
    BoldIcon,
    FeatherIcon,
    CodeIcon,
    MonitorIcon,
    UserCheckIcon,
    GitMergeIcon,
    SettingsIcon,
    HeartIcon,
    countTo,
  },
};


</script>

<template>
  <div>
    <Navbar :nav-light="true" />

    <!-- Hero Start -->
    <section class="bg-half-260 pb-lg-0 pb-md-4 bg-primary d-table w-100">
      <div class="bg-overlay bg-black" style="opacity: 0.8; margin-bottom:40px;"></div>









      <div class="container"> 
        <div class="row position-relative" style="z-index: 1">





          
          <div class="col-md-7 col-12 mt-lg-5">






            
            <div class="title-heading">


              <h1 class="heading text-white font-weight-bold mb-4">
                 
                <span class="element font-weight-bold text-primary">
                  <span v-b-tooltip.hover title="Karukaj is an ancient best digital marketing agency or web development company in Bangladesh near Dhaka.  
                  Also known as low-priced best eCommerce website development company in Bangladesh. Be around us!" class="text-primary font-weight-bold">Karukaj </span> <vue-typer
                    :text="[
                      'DIGITAL',
                      'DESIGN',
                      'DEVELOPMENT',
                       'MARKETING',
                         'PRODUCTION',
                           'NETWORK',
                    ]"
                  ></vue-typer>
                </span> <br>Built for Business              
           </h1>

              <p class="para-desc2 text-white-50">
The power of the internet is now at your fingertips, and with <span v-b-tooltip.hover title="Karukaj is one of the best digital marketing 
agency in Bangladesh or the best web development company in Bangladesh near Dhaka. On the other hand our website design price in Bangladesh
 is very affordable and hence known as best eCommerce website development company in Bangladesh.">Karukaj </span><strong> DIGITAL</strong>, you’ll be doing great things in few days. Let’s get started.              </p>
              <div class="watch-video mt-4 pt-2">
                <a  v-b-tooltip.hover title="Create a free member account today" href="/estimate" class="btn btn-pink font-weight-bold mb-2"
                  >  Get a Price Estimate</a
                >
                <a href="javascript: void(0);" v-b-modal.modal-2 class="btn btn-icon btn-pills video-play-icon btn-primary m-1">
                  
                  <video-icon class="icons"></video-icon>
                </a>
                <b-modal
                  id="modal-2"
                  hide-footer
                  size="lg"
                  
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                <youtube  video-id="d4aijRJFOq4" ref="youtube"  width="100%"  height="432"  :player-vars="playerVars" ></youtube>
                </b-modal>   
                <span class="font-weight-bold text-uppercase text-white-50 small align-middle ml-2"></span>
              </div>
              <br>
            </div>
          </div>
          <!--end col-->














          <div class="col-md-5 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div class="shape-before">
              <div class="carousel-cell">
                <img
                  src="images/task/task.svg"
                  class="img-fluid mover"
                  alt=""
                />
              </div>
              <img
                src="images/shapes/shape1.png"
                class="img-fluid shape-img"
                alt=""
              />
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->













    </section>
    <!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 250"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M720 125L2160 0H2880V250H0V125H720Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!-- Hero End -->






   <!-- Feature Start -->
    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">We are Built for Business</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                <span class="text-primary font-weight-bold">
                  
                  
                  You Should Learn in 2022. Find the answers to frequently asked questions in the following categories:
                  
                  Karukaj</span> is a team of people who come together with a set of creative values that define the kind of business we want to establish. 
                  
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div
            class="col-md-4 col-12"
            v-for="(item, index) of featuresData"
            :key="index"
          >
            <div class="features mt-5">
              <div class="image position-relative d-inline-block">
                <i :class="`${item.icon} h1 text-primary`"></i>
              </div>

              <div class="content mt-4">
                <h5>{{ item.title }}</h5>
                <p class="text-muted mb-0">{{ item.description }}</p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->



      <div class="container mt-100 mt-60">
        <div class="row">
          <div class="col-lg-6">
            <div class="section-title text-center text-lg-left">
              <h4 class="title mb-4">
                Trusted client by over <br />
                <span class="text-primary">1000+</span> of the world’s
              </h4>
              <p class="text-muted mb-0 mx-auto para-desc">
                <span class="text-primary font-weight-bold">Karukaj</span> is a fastest growing social media marketing, seo, web design & development company in Bangladesh. Customer satisfaction is our #1 priority to Grow & Scale their business. 

           
              </p>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6">
            <div class="row" id="counter">
              <div class="col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="content text-center">
                  <h1 class="mb-0">
                    <countTo
                      :startVal="1"
                      :duration="4000"
                      :endVal="500"
                    ></countTo
                    >+
                  </h1>
                  <ul class="list-unstyled mb-0 h5">
                    <li class="list-inline-item">
                      <i class="mdi mdi-heart text-danger"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-heart text-danger"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-heart text-danger"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-heart text-danger"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-heart text-danger"></i>
                    </li>
                  </ul>
                  <h6>Trusted Users</h6>
                </div>
              </div>

              <div class="col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="content text-center">
                  <h1 class="mb-0">
                    <countTo
                      :startVal="1"
                      :duration="4000"
                      :endVal="5000"
                    ></countTo
                    >+
                  </h1>
                  <ul class="list-unstyled mb-0 h5">
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                  </ul>
                  <h6>Task Complete</h6>
                </div>
              </div>
            </div>





            <div class="row justify-ontent-right mt-4">
              
              <div class="col-lg-3 col-md-3 col-6 text-center py-4">
                <img
                  src="images/client/amazon.svg"
                  class="avatar avatar-ex-sm"
                  alt=""
                />
              </div>
              <!--end col-->

              <div class="col-lg-3 col-md-3 col-6 text-center py-4">
                <img
                  src="images/client/google.svg"
                  class="avatar avatar-ex-sm"
                  alt=""
                />
              </div>
              <!--end col-->

              <div class="col-lg-3 col-md-3 col-6 text-center py-4">
                <img
                  src="images/client/lenovo.svg"
                  class="avatar avatar-ex-sm"
                  alt=""
                />
              </div>
              <!--end col-->

              <div class="col-lg-3 col-md-3 col-6 text-center py-4">
                <img
                  src="images/client/paypal.svg"
                  class="avatar avatar-ex-sm"
                  alt=""
                />
              </div>
              <!--end col-->


            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
                    <div class="kd-custom-text-right">
              Trusted by over 100+ of the world’s leading outsourcing
              companies
            </div>
      </div>
      <!--end container-->






          <div class="col-12 mt-4 pt-2 text-center">



          </div>
          <!--end col-->


















      <div class="container">
        <div class="row justify-ontent-center">
          <div class="col-12">
            <div class="section-title text-center mb-4 pb-2">
              <h4 class="title mb-4">Solutions made for you</h4>
              <p class="text-muted para-desc mb-0 mx-auto">
              
               We’ve been in business for over 13 years, where our creativity is your success that can provide everything you need to generate awareness, drive traffic, connect.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div
              class="media key-feature align-items-center p-3 rounded-md shadow"
            >
              <div class="icon text-center rounded-circle mr-3">
                <monitor-icon
                  class="fea icon-ex-md text-primary"
                ></monitor-icon>
              </div>
              <div class="media-body">
                <h4 class="title mb-0">Fully Responsive</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div
              class="media key-feature align-items-center p-3 rounded-md shadow"
            >
              <div class="icon text-center rounded-circle mr-3">
                <heart-icon class="fea icon-ex-md text-primary"></heart-icon>
              </div>
              <div class="media-body">
                <h4 class="title mb-0">Browser Compatibility</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div
              class="media key-feature align-items-center p-3 rounded-md shadow"
            >
              <div class="icon text-center rounded-circle mr-3">
                <eye-icon class="fea icon-ex-md text-primary"></eye-icon>
              </div>
              <div class="media-body">
                <h4 class="title mb-0">Retina Ready</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div
              class="media key-feature align-items-center p-3 rounded-md shadow"
            >
              <div class="icon text-center rounded-circle mr-3">
                <bold-icon class="fea icon-ex-md text-primary"></bold-icon>
              </div>
              <div class="media-body">
                <h4 class="title mb-0">Based On Bootstrap 4</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div
              class="media key-feature align-items-center p-3 rounded-md shadow"
            >
              <div class="icon text-center rounded-circle mr-3">
                <feather-icon
                  class="fea icon-ex-md text-primary"
                ></feather-icon>
              </div>
              <div class="media-body">
                <h4 class="title mb-0">Feather Icons</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div
              class="media key-feature align-items-center p-3 rounded-md shadow"
            >
              <div class="icon text-center rounded-circle mr-3">
                <code-icon class="fea icon-ex-md text-primary"></code-icon>
              </div>
              <div class="media-body">
                <h4 class="title mb-0">Built With SASS</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div
              class="media key-feature align-items-center p-3 rounded-md shadow"
            >
              <div class="icon text-center rounded-circle mr-3">
                <user-check-icon
                  class="fea icon-ex-md text-primary"
                ></user-check-icon>
              </div>
              <div class="media-body">
                <h4 class="title mb-0">W3c Valid Code</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div
              class="media key-feature align-items-center p-3 rounded-md shadow"
            >
              <div class="icon text-center rounded-circle mr-3">
                <git-merge-icon
                  class="fea icon-ex-md text-primary"
                ></git-merge-icon>
              </div>
              <div class="media-body">
                <h4 class="title mb-0">Flaticon Icons</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div
              class="media key-feature align-items-center p-3 rounded-md shadow"
            >
              <div class="icon text-center rounded-circle mr-3">
                <settings-icon
                  class="fea icon-ex-md text-primary"
                ></settings-icon>
              </div>
              <div class="media-body">
                <h4 class="title mb-0">Easy to customize</h4>
              </div>
            </div>
          </div>
          <!--end col-->

        </div>
        <!--end row-->
      </div>
      <!--ed container-->









          <div class="col-12 mt-4 pt-2 text-center">

 <span class="text-primary font-weight-bold">And a whole lot more…</span> 

          </div>
          <!--end col-->













          <div class="col-12 mt-4 pt-2 text-center">
                <div class="alert alert-light alert-pills shadow" role="alert">          
                <span class="badge badge-pill badge-danger mr-1">OR</span>
                <span class="content">
                  Do you need personal <span class="text-primary">Creative Assistant?</span></span
                >
              </div>
    
  <br>   <br> 
           We believe = <span class="text-primary">Partner + solution = results</span> 
  <br>   <br> 

              <h4 class="title mb-4">Work with our team remotely from anywhere and anytime</h4>
            

          </div>
          <!--end col-->



      
     <div class="container">
        <div class="row align-items-center">

          <div class="col-lg-5 col-md-6">
            <img
              src="images/contact.svg"
              class="img-fluid"
              alt=""
            />
          </div>
          <!--end col-->



          <div class="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div class="section-title text-md-left text-center">

              <h5 class="text-primary">
                Job Type:
                <vue-typer
                  :text="['Task Hours', 'Project Base', 'Project Milestone', 'Package Plan', 'Task Per Day']"
                ></vue-typer>
              </h5>
              <p class="text-muted mb-0 para-desc">
                It’s about understanding the marketing challenges behind your business
              </p>

              <div class="media align-items-center text-left mt-4 pt-2">
                <div class="text-primary h4 mb-0 mr-3 p-3 rounded-md shadow">
                  <i class="uil uil-capture"></i>
                </div>
                <div class="media-body">
                  <a href="javascript:void(0)" class="text-dark h6"
                    >Dream & Design, High Impact Dynamic Visuals To sell Anything</a
                  >
                </div>
              </div>

              <div class="media align-items-center text-left mt-4">
                <div class="text-primary h4 mb-0 mr-3 p-3 rounded-md shadow">
                  <i class="uil uil-file"></i>
                </div>
                <div class="media-body">
                  <a href="javascript:void(0)" class="text-dark h6"
                    >Launch Professional Marketing Campaigns and Sales Funnels</a
                  >
                </div>
              </div>

              <div class="media align-items-center text-left mt-4">
                <div class="text-primary h4 mb-0 mr-3 p-3 rounded-md shadow">
                  <i class="uil uil-credit-card-search"></i>
                </div>
                <div class="media-body">
                  <a href="javascript:void(0)" class="text-dark h6"
                    >Find Better Trust, Set Instant Metting and Serve To the point</a
                  >
                </div>
              </div>
  <br/>



            </div>
          </div>
          <!--end col-->




        </div>
        <!--end row-->
      </div>
      <!--end container-->









     <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="section-title">
              <h4 class="title mb-4">
                See everything about <br>
                <span class="text-primary">Karukaj</span> Business
              </h4>
              <p class="text-muted para-desc">
We offer a results-driven holistic marketing approach to increase your brand’s reach and dominate your competition.
              </p>
              <p class="mt-3 h6 text-primary font-weight-bold           
"
                >Don't know where to start?</p>





              <ul class="list-unstyled text-muted">
                <li>
                  <arrow-right-circle-icon
                    class="fea icon-sm text-primary mr-2"
                  ></arrow-right-circle-icon
                  >We can start a brainstorming free project storytelling
                </li>
                <li>
                  <arrow-right-circle-icon
                    class="fea icon-sm text-primary mr-2"
                  ></arrow-right-circle-icon
                  >Or, we'll take full responsibility for non-tech members.
                </li>
              </ul>

            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6">
            <div class="row ml-lg-5" id="counter">
              <div class="col-md-6 col-12">
                <div class="row">
                  <div class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                    <div
                      class="card counter-box border-0 bg-light shadow text-center rounded"
                    >
                      <div class="card-body py-5">
                        <h2 class="mb-0">
                          <countTo
                            :startVal="3"
                            :endVal="97"
                            :duration="4000"
                          ></countTo
                          >%
                        </h2>
                        <h5 class="counter-head mb-0">Happy Client</h5>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12 mt-4 pt-2">
                    <div
                      class="card counter-box border-0 bg-primary shadow text-center rounded"
                    >
                      <div class="card-body py-5">
                        <h2 class="text-light title-dark mb-0">
                          <countTo
                            :startVal="1"
                            :endVal="15"
                            :duration="4000"
                          ></countTo
                          >+
                        </h2>
                        <h5 class="counter-head mb-0 title-dark text-light">
                          Awards
                        </h5>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end Row-->
              </div>
              <!--end col-->

              <div class="col-md-6 col-12">
                <div class="row pt-lg-4 mt-lg-4">
                  <div class="col-12 mt-4 pt-2">
                    <div
                      class="card counter-box border-0 bg-success shadow text-center rounded"
                    >
                      <div class="card-body py-5">
                        <h2 class="text-light title-dark mb-0">
                          <countTo :startVal="0" :endVal="2" :duration="4000">
                          </countTo
                          >K
                        </h2>
                        <h5 class="counter-head mb-0 title-dark text-light">
                          Job Placement
                        </h5>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12 mt-4 pt-2">
                    <div
                      class="card counter-box border-0 bg-light shadow text-center rounded"
                    >
                      <div class="card-body py-5">
                        <h2 class="mb-0">
                          <countTo
                            :startVal="3"
                            :endVal="98"
                            :duration="4000"
                          ></countTo
                          >%
                        </h2>
                        <h5 class="counter-head mb-0">Project Complete</h5>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end Row-->
              </div>
              <!--end col-->
            </div>
            <!--end Row-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->









       <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-6 order-1 order-md-2">
            <div class="text-right">
              <img
                src="images/social/social.svg"
                class="img-fluid"
                alt=""
              />
            </div>
          </div>
          <!--end col-->

          <div
            class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0"
          >
            <div class="section-title text-md-left text-center">
              <h4 class="title mb-4">
                Whats our clients said <br />
                about Karukaj project
              </h4>
              <p class="text-muted mb-0 para-desc">
            
                <span class="text-primary font-weight-bold">Karukaj</span> strive to educate and collaborate with like-minded businesses to make a difference environmentally and socially.

              </p>

              <div class="row justify-content-center">
                <div class="col-lg-12 mt-4">
                  <carousel
                    id="single-owl"
                    class="owl-carousel owl-theme"
                    dir="ltr"
                    :per-page="1"
                    :loop="true"
                  >
                    <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/01.jpg"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded bg-white position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-muted mt-2">
                            " It seems that only fragments of the original text
                            remain in the Lorem Ipsum texts used today. "
                          </p>
                          <h6 class="text-primary">
                            - Thomas Israel
                            <small class="text-muted">C.E.O</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                    <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/02.jpg"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded bg-white position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star-half text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-muted mt-2">
                            " One disadvantage of Lorum Ipsum is that in Latin
                            certain letters appear more frequently than others.
                            "
                          </p>
                          <h6 class="text-primary">
                            - Barbara McIntosh
                            <small class="text-muted">M.D</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                    <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/03.jpg"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded bg-white position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-muted mt-2">
                            " The most well-known dummy text is the 'Lorem
                            Ipsum', which is said to have originated in the 16th
                            century. "
                          </p>
                          <h6 class="text-primary">
                            - Carl Oliver <small class="text-muted">P.A</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                    <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/04.jpg"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded bg-white position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-muted mt-2">
                            " According to most sources, Lorum Ipsum can be
                            traced back to a text composed by Cicero. "
                          </p>
                          <h6 class="text-primary">
                            - Christa Smith
                            <small class="text-muted">Manager</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                    <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/05.jpg"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded bg-white position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-muted mt-2">
                            " There is now an abundance of readable dummy texts.
                            These are usually used when a text is required. "
                          </p>
                          <h6 class="text-primary">
                            - Dean Tolle
                            <small class="text-muted">Developer</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                    <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/06.jpg"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded bg-white position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-muted mt-2">
                            " Thus, Lorem Ipsum has only limited suitability as
                            a visual filler for German texts. "
                          </p>
                          <h6 class="text-primary">
                            - Jill Webb
                            <small class="text-muted">Designer</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                  </carousel>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

    </section>
    <!--end section-->
    <!-- End -->

 






 


    <!-- Start -->
 



      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="section-title text-center mb-4 pb-2">
              <h6 class="text-primary">Work Process</h6>
              <h4 class="title mb-4">How do we works ?</h4>
              <p>Get Started by sign up to our  <span class="text-primary font-weight-bold">Karukaj</span> Members Task Management System <span class="text-primary font-weight-bold">KD NEWTORK</span></p>
             
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-md-4 mt-4 pt-2">
            <div
              class="card features work-process bg-transparent process-arrow border-0 text-center"
            >
              <div class="icons rounded h1 text-center text-primary px-3">
                <i class="uil uil-presentation-edit"></i>
              </div>

              <div class="card-body">
                <h4 class="title text-dark">1. Find the Talent you need</h4>
                <p class="text-muted mb-0">
                  Compare prices, portfolios, community recommendations and more to select the Talent 
                  you are most excited to work with. If you have a specific question, simply send them an enquiry.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
            <div
              class="card features work-process bg-transparent process-arrow border-0 text-center"
            >
              <div class="icons rounded h1 text-center text-primary px-3">
                <i class="uil uil-airplay"></i>
              </div>

              <div class="card-body">
                <h4 class="title text-dark">2. Supply your brief</h4>
                <p class="text-muted mb-0">
                  Provide a job brief for the Talent to review. At this point Envato Studio takes payment, which is held until the job is completed and you are happy with the outcome.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
            <div
              class="card features work-process bg-transparent d-none-arrow border-0 text-center"
            >
              <div class="icons rounded h1 text-center text-primary px-3">
                <i class="uil uil-image-check"></i>
              </div>

              <div class="card-body">
                <h4 class="title text-dark">3. Manage your job online</h4>
                <p class="text-muted mb-0">
                 Share files and feedback easily with inbuilt messaging and job management tools. 
                 Your Talent will complete the job within the agreed turnaround time, and number of revisions.


                </p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->










            <div class="row align-items-end mb-4 pb-4">
                <div class="col-md-8">
                    <div class="section-title text-center text-md-left">
                       <h6 class="text-primary font-weight-bold">Let’s make things happen</h6>
                        
                        <h4 class="title mb-4">Money can’t buy time;
<br>
 Unless you’ve got Plan.
</h4>
                        <p class="text-muted mb-0 para-desc">Save your time to get it up and running and see everything OnBoard
                         that can provide everything you need for <span class="text-primary"> online presence.</span></p>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 mt-4 mt-sm-0">


                <div class="col-12 text-center mt-4 pt-2">
                    <router-link to="/how-works" class="btn btn-outline-primary">EXPLORE MORE <i class="mdi mdi-chevron-right"></i></router-link>
                </div>

<br><br>


                </div>
                <!--end col-->
            </div>
            <!--end row-->












      </div>
      <!--end container-->

   

















    <section
      class="section bg-white pt-0"
      :style="{
        background:
          'url(' +
          require('../../public/images/real/build.png') +
          ') bottom no-repeat',
      }"
    >
  
      
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
       
              <div class="mt-4 pt-2">
                 <h5 class="mb-4">Are you the kind of entrepreneur who takes action?</h5>
    <a href="/help-center" class="btn btn-primary"
                >FREE SIGNUP TODAY<i class="mdi mdi-chevron-right"></i
              ></a>


  
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <!--end container-->
    </section>
    <!--end section-->














    <!-- End -->
<div class="position-relative">
        <div class="shape overflow-hidden text-footer">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!--end section-->
















    <!-- Testi Start -->
    <section
      class="section"
      style="background: #fff center center">
    


      <div class="container">






           <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                        <h4>Virtues of Digital Marketing</h4>


                    </div>
                </div>
                <!--end col-->


        <div
          class="p-4 rounded shadow bg-primary position-relative"
          style="z-index: 1"
        >
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="progress-box">
                <h6 class="title text-light title-dark">Web Designing</h6>
                <div
                  class="progress title-bg-dark"
                  style="height: 10px; padding: 1px"
                >
                  <div
                    class="progress-bar position-relative bg-black"
                    style="width: 84%"
                  >
                    <div
                      class="progress-value d-block text-light title-dark h6"
                    >
                      84%
                    </div>
                  </div>
                </div>
              </div>
              <!--end process box-->
              <div class="progress-box mt-4">
                <h6 class="title text-light title-dark">Web Development</h6>
                <div
                  class="progress title-bg-dark"
                  style="height: 10px; padding: 1px"
                >
                  <div
                    class="progress-bar position-relative bg-black"
                    style="width: 75%"
                  >
                    <div
                      class="progress-value d-block text-light title-dark h6"
                    >
                      75%
                    </div>
                  </div>
                </div>
              </div>
              <!--end process box-->
              <div class="progress-box mt-4">
                <h6 class="title text-light title-dark">Game Development</h6>
                <div
                  class="progress title-bg-dark"
                  style="height: 10px; padding: 1px"
                >
                  <div
                    class="progress-bar position-relative bg-black"
                    style="width: 79%"
                  >
                    <div
                      class="progress-value d-block text-light title-dark h6"
                    >
                      79%
                    </div>
                  </div>
                </div>
              </div>
              <!--end process box-->
            </div>
            <!--end col-->

            <div class="col-md-6 col-12">
              <div class="progress-box mt-4 mt-sm-0">
                <h6 class="title text-light title-dark">App Development</h6>
                <div
                  class="progress title-bg-dark"
                  style="height: 10px; padding: 1px"
                >
                  <div
                    class="progress-bar position-relative bg-black"
                    style="width: 84%"
                  >
                    <div
                      class="progress-value d-block text-light title-dark h6"
                    >
                      84%
                    </div>
                  </div>
                </div>
              </div>
              <!--end process box-->
              <div class="progress-box mt-4">
                <h6 class="title text-light title-dark">Digital Marketing</h6>
                <div
                  class="progress title-bg-dark"
                  style="height: 10px; padding: 1px"
                >
                  <div
                    class="progress-bar position-relative bg-black"
                    style="width: 75%"
                  >
                    <div
                      class="progress-value d-block text-light title-dark h6"
                    >
                      75%
                    </div>
                  </div>
                </div>
              </div>
              <!--end process box-->
              <div class="progress-box mt-4">
                <h6 class="title text-light title-dark">
                  Full stack Development
                </h6>
                <div
                  class="progress title-bg-dark"
                  style="height: 10px; padding: 1px"
                >
                  <div
                    class="progress-bar position-relative bg-black"
                    style="width: 79%"
                  >
                    <div
                      class="progress-value d-block text-light title-dark h6"
                    >
                      79%
                    </div>
                  </div>
                </div>
              </div>
              <!--end process box-->
            </div>
            <!--end col-->
          </div>
          <!--end row -->
        </div>

      </div>
      <!--end container-->




        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="video-solution-cta position-relative" style="z-index: 1;">







                        <div class="content mt-md-4 pt-md-2">
                            <div class="row justify-content-center">


                                                                    <h4 class="title text-white title-dark mb-0">Meet Experience Team Member</h4>

          
                                <div class="col-lg-10 text-center">

                                    <div class="row align-items-center">
                                        <div class="col-md-6 mt-4 pt-2">

                       <div class="d-flex mb-4">
                            <h6 class="text-white-50">Team</h6>

                            <span class="text-white h1 mb-0"><span class="counter-value display-1 font-weight-bold" data-count="15">10</span>+</span>
                            <span class="h6 align-self-end ml-2">Years in<br>Business</span>
                        </div> 




                                            <div class="section-title text-md-left">
   <h6 class="text-white-50">Team</h6>


                                            </div>
                                        </div>

                                        <div class="col-md-6 col-12 mt-4 pt-md-2">
                                            <div class="section-title text-md-left">

                                                <p class="text-white-50 para-desc">OnBorad on KD Network that can provide everything you need to generate awareness, drive traffic, connect.</p>
                                                <a href="javascript:void(0)" class="text-white title-dark">Call me when you need clever marketing strategies<arrow-right-icon class="fea icon-sm"></arrow-right-icon></a>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row -->
            <div class="feature-posts-placeholder bg-primary"></div>
        </div>
        <!--end container-->


















  
        <div class="container mt-100 mt-60">
          <div class="rounded multi-color-bg p-lg-5 p-4">
            <div class="row align-items-end">
              <div class="col-md-8">
                <div class="section-title text-md-left text-center">
                  <h4 class="title mb-3 text-white title-dark">
                    We're passionate about driving results online.<span v-b-tooltip.hover title="Karukaj DIGITAL is one of the best digital marketing agency in Bangladesh near Dhaka, 
                            where KD Network is the digital agency management area of ' KD ' to offer digital marketing service and handle full operations. Get Started." class="text-danger font-weight-bold">*</span>                  
                  </h4>
                 
  
  
                </div>
              </div>
              <!--end col-->
  
  
  
  
              <div class="col-md-4 mt-4 mt-sm-0">
  
  
  
  
  
  
                <div class="text-md-right text-center">
  
                  <a href="/support" class="btn btn-outline-primary"
                >Go to support page <i class="mdi mdi-chevron-right"></i
              ></a>

                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
        </div>
        <!--end container-->




























    </section>
    <!--end section-->
    <!-- End -->










    <section
      class="section"
      style="background: url('images/course/online/USR20.jpg') center center"
    >
      <div class="bg-overlay"></div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-7">
            <div class="section-title mr-lg-4">
              <h4 class="title title-dark text-light mb-4">
                Actions speak louder than words <span v-b-tooltip.hover title="Another best-experienced digital marketing company and website development 
                company, who provides freelance web developer and expert digital marketing service in Bangladesh. ">!</span>
              </h4>
              <p class="text-light para-dark para-desc mb-0">
              <span v-b-tooltip.hover title="Another best-experienced digital marketing company and website development 
                company, who provides freelance web developer and expert digital marketing service in Bangladesh. "> We</span> can work with you for either a short project or ongoing retainer to best suit your digital needs. Let’s make things happen.
              </p>
              <div class="watch-video mt-4 pt-2">
                <a href="/signup" class="btn btn-primary mb-2 mr-2"
                  >Enroll Now</a
                >

              </div>

            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="card rounded shadow border-0">
              <div class="card-body">
                <h5 class="text-center">Become A Member</h5>

                <form>
                  <div class="row mt-4">
                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <label
                          >Your Name <span class="text-danger">*</span></label
                        >
                      
                        <div class="position-relative">
                            <user-icon class="fea icon-sm icons"></user-icon>
                          <input
                            name="name"
                            id="name3"
                            type="text"
                            class="form-control pl-5"
                            placeholder="Name"
                            required=""
                          />
                        </div>
                      </div>
                    </div>
                    <!--end col-->

                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <label
                          >Your Email <span class="text-danger">*</span></label
                        >
                        <div class="position-relative">
                          <mail-icon class="fea icon-sm icons"></mail-icon>
                          <input
                            type="email"
                            id="email3"
                            class="form-control pl-5"
                            placeholder="Email"
                            name="email"
                            required=""
                          />
                        </div>
                      </div>
                    </div>
                    <!--end col-->

                    <div class="col-md-12">
                      <div class="form-group">
                        <label
                          >Your Phone no. :<span class="text-danger"
                            >*</span
                          ></label
                        >
                        <div class="position-relative">
                          <phone-icon class="fea icon-sm icons"></phone-icon>
                          <input
                            name="number"
                            id="number"
                            type="number"
                            class="form-control pl-5"
                            placeholder="Phone no. :"
                            required=""
                          />
                        </div>
                      </div>
                    </div>
                    <!--end col-->

                    <div class="col-lg-12 mb-0">
                      <button class="btn btn-primary btn-block">
                        <a href="/signup" >Enroll Now</a >
                      </button>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
                <!--end form-->
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->




























































      <div class="container mt-100 mt-60">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-6 col-12">


            <div class="title-heading mt-5 pt-4">
                <span class="text-primary font-weight-bold">Support over our ticketing system is available 24/7</span>
                        <h1 class="heading">We're here to support you</h1>
                        <p class="text-dark"><span class="text-primary font-weight-bold">Karukaj </span><strong> DIGITAL</strong> friendly chat representatives successfully resolve 90%+ of the inquiries they get. 
                        They reply within 15 minutes on average and in the majority of the cases the issues get resolved with a single reply.

                          <br>
                        
                        </p>

                        <div class="media contact-detail align-items-center mt-3">
                            <div class="icon">
                                <mail-icon class="fea icon-m-md text-dark mr-3"></mail-icon>
                            </div>
                            <div class="media-body content">
                                <h5 class="title font-weight-bold mb-0">Email</h5>
                                <img src="images/ml.png" alt="">
                            </div>
                        </div>

                        <div class="media contact-detail align-items-center mt-3">
                            <div class="icon">
                                <phone-icon class="fea icon-m-md text-dark mr-3"></phone-icon>
                            </div>
                            <div class="media-body content">
                                <h5 class="title font-weight-bold mb-0">Phone</h5>
                                <a href="tel:+8801714641541" class="text-primary">+880 1714-641-541</a><br>
                                 <a href="tel:+88009696399303" class="text-primary">+880 9696021541</a>
                            </div>
                        </div>

                        <div class="media contact-detail align-items-center mt-3">
                            <div class="icon">
                                <map-pin-icon class="fea icon-m-md text-dark mr-3"></map-pin-icon>
                            </div>
                            <div class="media-body content">
                                 <h5 class="title font-weight-bold mb-0">Location</h5>
                                <a href="javascript: void(0);" class="video-play-icon text-primary" v-b-modal.modal-1>View on Google map</a>
                            </div>
                            <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-1" size="lg" centered body-class="p-0">
                                <iframe height="500" width="800" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d4342.122730387992!2d90.41996321855859!3d23.773204123512432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1643032484214!5m2!1sen!2sbd" style="border:0"></iframe>
                            </b-modal>
                        </div>
                        <ul class="list-unstyled social-icon mb-0 mt-4">
                            <li class="list-inline-item"><a href="https://www.facebook.com/karukaj.digital" class="rounded">
                                    <facebook-icon class="fea icon-sm fea-social"></facebook-icon>
                                </a></li>
                            <li class="list-inline-item ml-1"><a href="https://www.instagram.com/karukaj.digital" class="rounded">
                                    <instagram-icon class="fea icon-sm fea-social"></instagram-icon>
                                </a></li>
                            <li class="list-inline-item ml-1"><a href="https://www.twitter.com/karukaj_digital" class="rounded">
                                    <twitter-icon class="fea icon-sm fea-social"></twitter-icon>
                                </a></li>
                            <li class="list-inline-item ml-1"><a href="https://www.linkedin.com/company/karukajdigital/" class="rounded">
                                    <linkedin-icon class="fea icon-sm fea-social"></linkedin-icon>
                                </a></li>
                            <li class="list-inline-item ml-1"><a href="https://github.com/karukaj/" class="rounded">
                                    <github-icon class="fea icon-sm fea-social"></github-icon>
                                </a></li>
                            <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                                    <youtube-icon class="fea icon-sm fea-social"></youtube-icon>
                                </a></li>
                            <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                                    <gitlab-icon class="fea icon-sm fea-social"></gitlab-icon>
                                </a></li>
                        </ul>
                        <!--end icon-->
                    </div>



                </div>

                <div class="col-lg-5 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="card rounded border-0 shadow-lg ml-lg-5">
                        <div class="card-body">
                            <div class="text-center">
                                <img src="images/1630999550.jpg" class="avatar avatar-medium rounded-circle shadow" alt="">
                                <div class="section-title">
                                    <h6 class="my-3 font-weight-bold">Virtual Assistant</h6>
                                    <p class="text-muted para-desc mx-auto mb-0"><span class="text-primary font-weight-bold">Greetings Dear Sir/Madam,</span><br>
Welcome to Karukaj DIGITAL's Official Website! I'll guide you through the Karukaj experience.</p>
                                </div>
                            </div>

                            <div class="content mt-4 pt-2">
                                <form>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group ">
                                                <label>Name : <span class="text-danger">*</span></label>
                                                <div class="position-relative">
                                                <user-icon class="fea icon-sm icons"></user-icon>
                                                <input type="text" class="form-control pl-5" placeholder="Name" name="name" required="">
                                                </div>
                                            </div>
                                        </div>
                                        <!--end col-->

                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label>Email : <span class="text-danger">*</span></label>
                                                       <div class="position-relative">
                                                <mail-icon class="fea icon-sm icons"></mail-icon>
                                                <input type="email" class="form-control pl-5" placeholder="Email" name="email" required="">
                                                       </div>
                                            </div>
                                        </div>
                                        <!--end col-->

                                        <div class="col-lg-12 mt-2">
                                            <button class="btn btn-soft-primary">Request call <arrow-right-icon class="fea icon-sm"></arrow-right-icon></button>
                                        </div>
                                        <!--end col-->
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--end container-->












    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="section-title">
              <p class="title mb-4">
                See everything about <br>   <span v-b-tooltip.hover title="Karukaj is one of the best digital marketing 
agency in Bangladesh or best web development company in Bangladesh near Dhaka. On the other hand our website design price in Bangladesh
 is very cheap. Also popular as the best eCommerce website development company in Bangladesh. Be around us." 
                class="text-primary" >Karukaj </span>  
                Business
              </p>
              <p class="text-muted para-desc">
We offer a results-driven holistic marketing approach to increase your brand’s reach and dominate your competition.
              </p>
 

              <h4 class="mt-3 h6 text-primary font-weight-bold">What makes us unique?</h4>
              

              <ul class="list-unstyled text-muted">
                 
              <li class="mb-0"> <span class="text-primary h5 mr-2"><i class="uil uil-star align-middle"></i>
              </span>Ensure privacy and transparency in login & ownership</li>


               <li class="mb-0"> <span class="text-primary h5 mr-2"><i class="uil uil-star align-middle"></i>
              </span>Offer world class technologies at your fingertips</li>

              <li class="mb-0"> <span class="text-primary h5 mr-2"><i class="uil uil-star align-middle"></i>
              </span>Ensure simplicity and minimalistic UI/UX Design</li>

              <li class="mb-0"> <span class="text-primary h5 mr-2"><i class="uil uil-star align-middle"></i>
              </span>Serve within time without compromising on quality</li>

 
              <li class="mb-0"> <span class="text-primary h5 mr-2"><i class="uil uil-star align-middle"></i>
              </span>Create high impact dynamic visuals that sell anything</li>


              <li class="mb-0"> <span class="text-primary h5 mr-2"><i class="uil uil-star align-middle"></i>
              </span>Always pay attention to brand's SEO and Storytelling</li>


              <li class="mb-0"> <span class="text-primary h5 mr-2"><i class="uil uil-star align-middle"></i>
              </span>Focus on more traffic for mind-blowing possibilities</li>


              </ul>

                            <div class="col-12 mt-4 pt-2 text-center">


                                <a href="javascript:void(0)" class="text-primary h6"><span v-b-tooltip.hover title="Our creative interactive after effect promo animation, or Ads, 
                                photography, 
video editing, and video post production service will charge your business marketing several times more forever." 
                >Explore more than just “enjoy your stay!” </span> <arrow-right-icon class="fea icon-sm"></arrow-right-icon></a>
                            </div>

            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6">
            <div class="row ml-lg-5" id="counter">
              <div class="col-md-6 col-12">
                <div class="row">
                  <div class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                    <div
                      class="card counter-box border-0 bg-violate-blue shadow text-center rounded"
                    >
                      <div class="card-body text-light py-5">
                        <h2 class="mb-0">
                          <countTo
                            :startVal="3"
                            :endVal="97"
                            :duration="4000"
                          ></countTo
                          >%
                        </h2>
                        <h5 class="counter-head mb-0">Happy Client</h5>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12 mt-4 pt-2">
                    <div
                      class="card counter-box border-0 bg-yello-lemon shadow text-center rounded"
                    >
                      <div class="card-body py-5">
                        <h2 class="title-dark mb-0">
                          <countTo
                            :startVal="1"
                            :endVal="2500"
                            :duration="4000"
                          ></countTo
                          >+
                        </h2>
                        <h5 class="counter-head mb-0 title-dark">
                          Design Work
                        </h5>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end Row-->
              </div>
              <!--end col-->

              <div class="col-md-6 col-12">
                <div class="row pt-lg-4 mt-lg-4">
                  <div class="col-12 mt-4 pt-2">
                    <div
                      class="card counter-box border-0 bg-success shadow text-center rounded"
                    >
                      <div class="card-body py-5">
                        <h2 class="text-light title-dark mb-0">
                          <countTo :startVal="0" :endVal="800" :duration="4000">
                          </countTo
                          >+
                        </h2>
                        <h5 class="counter-head mb-0 title-dark text-light">
                          Job Placement
                        </h5>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12 mt-4 pt-2">
                    <div
                      class="card counter-box border-0 bg-pink-blue shadow text-center rounded"
                    >
                      <div class="card-body text-light py-5">
                        <h2 class="mb-0">
                          <countTo
                            :startVal="3"
                            :endVal="98"
                            :duration="4000"
                          ></countTo
                          >%
                        </h2>
                        <h5 class="counter-head mb-0">Project Complete</h5>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end Row-->
              </div>
              <!--end col-->
            </div>
            <!--end Row-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->












    </section>
    <!--end section-->



  




  <!--strat section-->
  <section>

















<div class="container">


<div class="row">
<div class="col-12 mt-4 pt-2">
            <div class="col-12 text-center">
  <div class="section-title mb-4 pb-2">
                <h4 class="title mb-4">Associate Sponsor Network</h4>
    <p class="text-muted para-desc mx-auto mb-0">
      
      <span v-b-tooltip.hover title="Karukaj does outsourcing in Bangladesh. Besights, Karukaj is the most popular as the best e-commerce website development 
      company in Bangladesh, who have any kind of custom skills as a web development company in Bangladesh. Stay around us!" class="text-muted">Karukaj</span> believe in building each other and hence
    </p>
  </div>
</div>

  <carousel
    id="customer-testi"
    ref="carousel"
    dir="ltr"
    :per-page="1"
    class="owl-carousel owl-theme"
    :autoplay="false"
    :loop="true"
  >
    <slide>
<div class="customer-testi text-center">
<div class="container">
<div class="row justify-content-center">
<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
    src="images/about-us/evanto.png"
    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
    src="images/about-us/godaddy.png"
    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
    src="images/about-us/up-work.png"
    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
    src="images/about-us/hostgator.png"
    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
    src="images/about-us/fiverr.png"
    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
    src="images/about-us/namecheap.png"
    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->
</div>
<!--end row-->
</div>
<!--end container-->
      </div>
      <!--end customer testi-->
    </slide>
    <slide>
                    <div class="customer-testi text-center">
<div class="container">
<div class="row justify-content-center">
<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
   src="images/about-us/amazon-asspciates.png"

    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
    src="images/about-us/rocket-lawyer.png"
    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
    src="images/about-us/LegalShield.png"
    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img

     src="images/about-us/LegalZoom.png"

    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
   src="images/about-us/udemylogo.png"

    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
    src="images/about-us/godaddy-auctions.png"
    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->
</div>
<!--end row-->
</div>
<!--end container-->
      </div>
      <!--end customer testi-->
    </slide>
               <slide>
                    <div class="customer-testi text-center">
<div class="container">
<div class="row justify-content-center">
<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
   src="images/about-us/flippa.png"

    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
    src="images/about-us/freelancer.png"
    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
    src="images/about-us/jvzoobd.png"
    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img

     src="images/about-us/LegalZoom.png"

    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
   src="images/about-us/shopify.png"

    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
    src="images/about-us/BlueHost.png"
    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->
</div>
<!--end row-->
</div>
<!--end container-->
      </div>
      <!--end customer testi-->
    </slide>

 <slide>
                    <div class="customer-testi text-center">
<div class="container">
<div class="row justify-content-center">
<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
   src="images/about-us/grammarly.png"

    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
    src="images/about-us/daynadot.png "
    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
    src="images/about-us/name.com.png"
    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img

     src="images/about-us/hostinger.png"

    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
   src="images/about-us/hostpapa.png"

    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
    src="images/about-us/domain.com.png"
    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->
</div>
<!--end row-->
</div>
<!--end container-->
      </div>
      <!--end customer testi-->
    </slide>


               <slide>
                    <div class="customer-testi text-center">
<div class="container">
<div class="row justify-content-center">
<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
   src="images/about-us/wix.com.png"

    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
    src="images/about-us/aweber.png"
    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
    src="images/about-us/pack1.png"
    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img

     src="images/about-us/link1.png"

    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
   src="images/about-us/GtrrnGeek.png"

    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
    src="images/about-us/siteground.png"
    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->
</div>
<!--end row-->
</div>
<!--end container-->
      </div>
      <!--end customer testi-->
    </slide>



               <slide>
                    <div class="customer-testi text-center">
<div class="container">
<div class="row justify-content-center">
<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
   src="images/about-us/NameHero.png"

    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
    src="images/about-us/One.com.png"
    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
    src="images/about-us/inmotion.png"
    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img

     src="images/about-us/magicx.png"

    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
   src="images/about-us/kinsta.png"

    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->

<div class="col-lg-2 col-md-2 col-6 text-center py-4">
  <img
    src="images/about-us/inmotion.png"
    class="avatar avatar-kd-sm"
    alt=""
  />
</div>
<!--end col-->
</div>
<!--end row-->
</div>
<!--end container-->
      </div>
      <!--end customer testi-->
    </slide>



  </carousel>
</div>
<!--end col-->
</div>
<!--end row-->

</div>
<!--end row-->




        
        <div class="col-12 mt-100 text-center">








          <a  target="_blank" href="/knowledgebase" v-b-tooltip.hover title="Click here to visit" class="h6 bdprice black-color-font underline">VISIT HERE</a>


                        <h6 class="my-color-1">UNLOCK KNOWLEDGEBASE <i class="mdi mdi-chevron-right"></i></h6>
                     
                        
                      

                        <div class="d-flex mb-4">
                                                    <span class="h4 align-self-end mb-1">Free </span>

                          <span class="price h1 mb-0"> / </span>
                                                    <span class="h4 mb-0 mt-2"> <strike> $1000+</strike></span>

                        </div>

                        <ul class="list-unstyled mb-0 pl-0">
                          <li class="text-muted mb-0">
                            <span class="my-color-1 h5 mr-2"
                              ><i
                                class="uil uil-check-circle align-middle"
                              ></i></span
                            >Trendy Resources<span v-b-tooltip.hover title="web design and development or " class="text-danger">*</span>
                          </li>
                          <li class="text-muted mb-0">
                            <span class="my-color-1 h5 mr-2"
                              ><i
                                class="uil uil-check-circle align-middle"
                              ></i></span
                            >Shortcuts walkthrough
                           

                          </li>

                        </ul>











            <div class="section-title">
                <h4 class="title mb-4">Meet our expert staff remotely</h4>

<p>According to the task type KD Members Portal assigns different responsibilities to members and controls the full operations. Be around us</p>
          <div class="mb-4 alert alert-outline-primary alert-pills" role="alert">
          
          <span class="alert-content"> Signup to see details inside "KD Members Portal"  </span>
          <span class="badge badge-pill badge-outline-secondary mr-1"> Members are free to join </span>
          </div>

                


        </div>
        </div>
        <!--end col-->








    <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-01" size="lg" centered body-class="p-0">
                          <div class="mb-4 align-items-center">
                          <iframe width="100%" height="520" src="https://members.karukaj.net/forms/wtl/5344a22f754a486154add6b818665191" frameborder="0" allowfullscreen></iframe>
                          </div></b-modal>


</section>
<!--end section-->
<!-- End -->






    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>


</template>
